"use client";

import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { ValidQueryParamStructure } from "./useMultipleUrlStates";
import { shake } from "radash";
import { usePathname } from "next/navigation";

interface MultipleUrlStatesContext {
  optimisticState?: ValidQueryParamStructure;
  updateOptimisticState: (change: ValidQueryParamStructure) => void;
}

const Context = createContext<MultipleUrlStatesContext>({
  updateOptimisticState: () => null,
  optimisticState: undefined,
});

export const useMultipleUrlStatesContext = () => useContext(Context);

type Props = {
  children: ReactNode;
};

export const UseMultipleUrlStatesContextProvider = ({ children }: Props) => {
  const [optimisticState, setOptimisticState] = useState<ValidQueryParamStructure>(undefined);

  const pathName = usePathname();

  const updateOptimisticState = (change: ValidQueryParamStructure) =>
    setOptimisticState((s) => shake({ ...s, ...change }));

  //We must clear the optimistic state whenever navigation between paths happens, so it does not persist and old parameters are used
  useEffect(() => {
    setOptimisticState(undefined);
  }, [pathName]);

  return <Context.Provider value={{ optimisticState, updateOptimisticState }}>{children}</Context.Provider>;
};
