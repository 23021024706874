import { Link, LinkProps } from "@biblioteksentralen/react";
import NextLink from "next/link";
import { AnchorHTMLAttributes, ComponentProps, ForwardedRef, forwardRef } from "react";
import { UrlObject } from "url";
import { LocalizedNextLink } from "./LocalizedNextLink";

type LinkComponentProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> &
  LinkProps & { ref?: ForwardedRef<HTMLAnchorElement> };

interface InternalLinkProps extends Omit<LinkComponentProps, "href"> {
  href: string | UrlObject;
  linkComponent?: (props: LinkComponentProps) => JSX.Element;
  nextLinkProps?: Omit<ComponentProps<typeof NextLink>, "href">;
}

// eslint-disable-next-line react/display-name
const InternalLink = forwardRef((props: InternalLinkProps, ref?: ForwardedRef<HTMLAnchorElement>) => {
  const { href, linkComponent, nextLinkProps, ...rest } = props;
  const LinkComponent = linkComponent ?? Link;
  return (
    <LocalizedNextLink href={href} passHref legacyBehavior {...nextLinkProps}>
      <LinkComponent ref={ref} {...rest} />
    </LocalizedNextLink>
  );
});

export default InternalLink;
