import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { isEmpty, isObject, shake } from "radash";
import { ZodSchema } from "zod";
import { useQueryObject } from "../useQueryObject";
import { useMultipleUrlStatesContext } from "./UseMultipleUrlStatesContextProvider";

/**
 * A hook for storing and persisting state in url as query params. Persists state when navigating, and allows users to share "deep urls" to a specific state. This hook also allows you to update several parameters in the url at once, preventing race conditions.
 * @param {ZodSchema} zodSchema A zod schema that defines the data structure you want to store in the url and is used to validate parameters. Query params must be treated as user-generated data since a user might maniplulate it manually or a malicious actor might share url's with bad/invalid query params.
 * @returns [state, updateState, getUrlToNewState]
 * @example
  const [state, updateState, getUrlToNewState] = useMultipleUrlStates(
    z.object({
      myString: z.string().optional(),
      myStingArray: z.array(z.string()).optional(),
      myNumber: z.number().optional(),
      myBoolean: z.boolean().optional(),
    })
  );

  return (
    <div>
      <button onClick={() => updateState({ myString: "Hello" })}>Update partial state</button>
      <button onClick={() => updateState({ myString: "World", myStingArray: ["My value"], myNumber: 10 })}>
        Update several parameters at once
      </button>
      <button onClick={() => updateState({ myString: undefined, myStingArray: undefined, myNumber: undefined })}>
        Unset several parameters
      </button>
      <a href={getUrlToNewState({ myString: "From a link", myStingArray: undefined })}>Link to a new state</a>
      <pre>{JSON.stringify(state, null, 2)}</pre>
    </div>
  );
 */

export function useMultipleUrlStates<Data extends ValidQueryParamStructure>(
  zodSchema: ZodSchema<Data>,
  options?: {
    /**
     * Optional base path to use instead of the current path. This is useful if you want links on other pages to send the user to the basepath with some state in the url
     */
    basePath?: string;
  }
): [Data | undefined, (change: Partial<Data>) => void, (change: Partial<Data>) => string] {
  const router = useRouter();

  const currentUrlState = useCurrentUrlState(zodSchema);
  // Optimistic state is used to update the ui before the url has changed. This is to improve the user experience and make the ui feel more responsive
  const [optimisticState, updateOptimisticState] = useOptimisticState(zodSchema);
  const state = optimisticState ?? currentUrlState;

  const getNewState = (change: Partial<Data>) => {
    const cleansedChange = Object.fromEntries(
      Object.entries(change).map(([key, value]) => {
        /**entries from incoming change needs to be cleansed if it is an object. This means empty values within the entry
         * must be removed (empty arrays and undefined values). If the cleansed entry then is an empty object, it is removed entirely from the change.
         */
        const valueIsObject = isObject(value);
        const existingValue = isObject(state?.[key]) ? (state[key] as object) : undefined;
        const newValue = valueIsObject
          ? shake({ ...existingValue, ...value }, (value) => !value || isEmpty(value))
          : value;
        const newValueOrUndefined = isEmpty(newValue) ? undefined : newValue;
        return [key, newValueOrUndefined];
      })
    );
    return { ...state, ...cleansedChange };
  };

  const pathName = usePathname();
  const searchParams = useSearchParams();

  const getUrlToNewState = (change: Partial<Data>): string => {
    // Persists other unrelated query-params in the url
    const clonedSearchParams = new URLSearchParams(searchParams ?? {});

    // Mapping over newState instead of change to prevent race conditions
    const newState = getNewState(change);

    // Update query params with changes. Removes key from url if the updated value equals undefined
    Object.entries(newState).forEach(([key, value]) =>
      isEmpty(value) ? clonedSearchParams.delete(key) : clonedSearchParams.set(key, JSON.stringify(value))
    );

    // TODO hvordan ta vare på andre ting som feks #hash i url
    return (options?.basePath ?? pathName) + (clonedSearchParams.size > 0 ? "?" + clonedSearchParams.toString() : "");
  };

  const updateState = (change: Partial<Data>) => {
    updateOptimisticState(getNewState(change));
    return router.replace(getUrlToNewState(change), { scroll: false });
  };

  return [state, updateState, getUrlToNewState];
}

type validParam = undefined | string | number | boolean | string[];

export type ValidQueryParamStructure = Record<string, validParam | Record<string, validParam>> | undefined;

const useCurrentUrlState = <Data>(schema: ZodSchema<Data>) => {
  const params = useQueryObject();
  const parsedQuery = safelyJSONParseAllParms(params);
  // Validates that the data we get from the url has the types and structure we're expecting
  return validateData(schema, parsedQuery);
};

const useOptimisticState = <Data>(schema: ZodSchema<Data>) => {
  const { optimisticState, updateOptimisticState } = useMultipleUrlStatesContext();
  return [
    optimisticState && validateData(schema, optimisticState),
    updateOptimisticState as (change?: Partial<Data>) => void,
  ] as const;
};

const validateData = <Data>(schema: ZodSchema<Data>, data: Record<string, unknown>) => {
  const validation = schema.safeParse(data);
  return validation.success ? validation.data : undefined;
};

const safelyJSONParseAllParms = (params: Record<string, string>) =>
  Object.fromEntries(Object.entries(params).map(([key, value]) => [key, safeParseJSON(value)]));

const safeParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    // Handle invalid JSON
    return undefined;
  }
};
