import { LinkOverlay } from "@biblioteksentralen/react";
import { ComponentProps, ForwardedRef, forwardRef } from "react";

import InternalLink from "./InternalLink";

type Props = Omit<ComponentProps<typeof InternalLink>, "linkComponent">;

// eslint-disable-next-line react/display-name
const InternalLinkOverlay = forwardRef((props: Props, ref: ForwardedRef<HTMLAnchorElement>) => (
  <InternalLink linkComponent={LinkOverlay} {...props} ref={ref} />
));

export default InternalLinkOverlay;
